






























































import { Component, Watch } from 'vue-property-decorator';
import Editor from '@tinymce/tinymce-vue';
import { mapActions, mapGetters } from 'vuex';
import { PageContent } from '@/model/PageContent';
import { mixins } from 'vue-class-component';
import ToastMixin from '@/mixins/ToastMixin';
import ToastVariant from '@/model/toast/toast-variant';

@Component({
    components: {
        Editor,
    },
    methods: {
        ...mapActions({
            fetch: 'pageContentStorage/fetchAboutUs',
            updateItem: 'pageContentStorage/updateAboutUs',
        }),
    },
    computed: {
        ...mapGetters({
            item: 'pageContentStorage/getAboutUs',
        }),
    },
})
export default class AboutUs extends mixins(ToastMixin) {
    fetch!: () => Promise<void>;
    updateItem!: (request: PageContent) => Promise<void>;

    item!: PageContent;
    editorKey = process.env.VUE_APP_EDITOR_KEY;

    loading = false;
    initialized = false;
    content = this.item?.content ?? '';

    @Watch('item')
    itemUpdate(): void {
        this.content = this.item?.content ?? '';
    }

    loaded(): void {
        if (!this.initialized) {
            this.initialized = true;
        }
    }

    mounted(): void {
        this.loading = true;
        this.fetch().then(() => {
            this.loading = false;
        });
    }

    update(): void {
        this.updateItem({ ...this.item, content: this.content }).then(() => {
            this.showToast(
                this.$t('about.update-success').toString(),
                this.$t('success-title').toString(),
                ToastVariant.SUCCESS,
            );
        });
    }
}
